var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: (_vm.sid ? "编辑" : "新增") + "骑手信息",
        visible: _vm.dialogVisible,
        "append-to-body": "",
        "custom-class": "dialog",
        "before-close": _vm.handleCancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 15 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号码", prop: "cPhone" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.cPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cPhone", $$v)
                              },
                              expression: "form.cPhone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "姓名", prop: "cName" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.cName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cName", $$v)
                              },
                              expression: "form.cName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 15 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "性别", prop: "bSex" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择性别" },
                              model: {
                                value: _vm.form.bSex,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "bSex", $$v)
                                },
                                expression: "form.bSex",
                              },
                            },
                            _vm._l(Object.keys(_vm.sexMap), function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: {
                                  label: _vm.sexMap[item],
                                  value: parseInt(item),
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "来源门店", prop: "rStore" } },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              options: _vm.storeList,
                              props: { value: "NODE_CODE", label: "NODE_NAME" },
                              filterable: "",
                              clearable: "",
                            },
                            on: { change: _vm.handleStoreChange },
                            model: {
                              value: _vm.form.rStore,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "rStore", $$v)
                              },
                              expression: "form.rStore",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 15 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "已加盟平台", prop: "rPlatform" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择加盟平台" },
                              model: {
                                value: _vm.form.rPlatform,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "rPlatform", $$v)
                                },
                                expression: "form.rPlatform",
                              },
                            },
                            _vm._l(_vm.platformList, function (item) {
                              return _c("el-option", {
                                key: item.sid,
                                attrs: { label: item.cName, value: item.sid },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所在区域", prop: "cRegion" } },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              options: _vm.baseArea,
                              props: { value: "NODE_CODE", label: "NODE_NAME" },
                              filterable: "",
                              clearable: "",
                            },
                            on: { change: _vm.handleAreaChange },
                            model: {
                              value: _vm.form.cRegion,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cRegion", $$v)
                              },
                              expression: "form.cRegion",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "常住地址", prop: "cAddress" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.cAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cAddress", $$v)
                              },
                              expression: "form.cAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "配送备注", prop: "cRemark" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.cRemark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cRemark", $$v)
                              },
                              expression: "form.cRemark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "特殊人群", prop: "bSpecial" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择特殊人群" },
                              model: {
                                value: _vm.form.bSpecial,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "bSpecial", $$v)
                                },
                                expression: "form.bSpecial",
                              },
                            },
                            _vm._l(
                              Object.keys(_vm.specialMap),
                              function (item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: {
                                    label: _vm.specialMap[item],
                                    value: parseInt(item),
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "启用状态", prop: "bStatus" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择启用状态" },
                              model: {
                                value: _vm.form.bStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "bStatus", $$v)
                                },
                                expression: "form.bStatus",
                              },
                            },
                            _vm._l(Object.keys(_vm.statusMap), function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: {
                                  label: _vm.statusMap[item],
                                  value: parseInt(item),
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }