<template>
  <div class="list-container" :style="{height: height}">
    <div class="toolbar">
      <el-button type="primary" @click="add">添加自取货骑手</el-button>
      <el-input placeholder="查询关键字" v-model="keyword" :clearable="true" :style="{ width:'250px',float: 'right'}">
        <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
      </el-input>
    </div>
    <el-table :data="riderList" highlight-current-row ref="table" v-loading="loading" :max-height="tableMaxHeight" :size="'mini'">
      <el-table-column label="序号" type="index" align="center" width="60px" :index="getIndex"></el-table-column>
      <el-table-column label="姓名" align="center" width="70px" prop="cName"></el-table-column>
      <el-table-column label="性别" align="center" width="60px" >
        <template #default="scope">
          {{ getSex(scope.row.bSex) }}
        </template>
      </el-table-column>
      <el-table-column label="手机号码" align="center" width="110px" prop="cPhone"></el-table-column>
      <el-table-column label="所在门店" align="center" width="200px" prop="sStore"></el-table-column>
      <el-table-column label="更新时间" align="center" width="130px" prop="tCreate" :formatter="dateFormatter"></el-table-column>
      <el-table-column label="状态" align="center" width="60px" >
        <template #default="scope">
          {{ getStatus(scope.row.bStatus) }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" align="center" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="view(scope.row)">详情</el-button>
          <el-popconfirm
            confirm-button-text='确定'
            cancel-button-text='取消'
            icon="el-icon-info"
            icon-color="red"
            title="是否确认删除数据项？"
            @confirm="deleteData(scope.row)"
          >
            <el-button type="text" slot="reference" :style="{ color:'rgb(241, 106, 106)',marginLeft:'5px'}">移除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :layout="pageLayout"
      :total="total"
      :current-page="pageNum"
      :page-sizes="pageSelect"
      :page-size="pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :style="{marginTop: '15px'}">
    </el-pagination>
    <AddSelfPickupGoodsRider :visible.sync="showAddSelfPickupGoodsRider" @close="handleCloseAddSelfPickupGoodsRider"/>
    <ViewRiderDetail :visible.sync="showViewRiderDetail" :sid="currentRiderSid" @close="handleCloseViewRiderDetail"/>
  </div>
</template>

<script>
import {getOrdFreightRiderSelfList,deleteOrdFreightRiderSelf} from "@/api/delivery";
import {sexMap,statusMap} from "@/utils/deliveryMap";
import {formatTime} from "@/utils/utils";
import AddSelfPickupGoodsRider from "@/views/components/DeliveryInfo/AddSelfPickupGoodsRider";
import ViewRiderDetail from "@/views/components/DeliveryInfo/ViewRiderDetail";

export default {
  name: "SelfPickupGoodsRiderList",
  components: {AddSelfPickupGoodsRider,ViewRiderDetail},
  props: {
    selectedStore: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      height: document.documentElement.clientHeight - 142 + "px",
      tableMaxHeight: document.documentElement.clientHeight - 260,
      currentSid:'',
      currentRiderSid:'',
      keyword:'',
      rStores:[],
      riderList:[],
      showAddSelfPickupGoodsRider: false,
      showViewRiderDetail: false,
      loading:false,
      total: 0, //总条数
      pageNum: 1, //当前页
      pageSize: 10, //每页显示条数
      pageSelect: [10, 20, 30, 50],
      pageLayout:"prev, pager, next,jumper,total,sizes",
    };
  },
  created() {
    //this.getRiderList();
  },
  mounted() {
  },
  computed: {
    getStatus() {
      return function(bStatus) {
        return statusMap[bStatus] || '未知';
      };
    },
    getSex() {
      return function(bSex) {
        return sexMap[bSex] || '未知';
      };
    },
  },
  methods: {
    getRiderList() {
      let params = {
        search:this.keyword,
        rStores:this.rStores,
        pageNum:this.pageNum,
        pageSize:this.pageSize,
      }
      this.loading = true;
      getOrdFreightRiderSelfList(params).then(result => {
        if (result) {
          this.riderList = result.rows;
          this.total = result.total;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    // 查询
    search(){
      this.pageNum = 1;
      this.getRiderList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.getRiderList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getRiderList();
    },
    add(){
      this.showAddSelfPickupGoodsRider = true;
    },
    view(item){
      this.currentSid = item.sid;
      this.currentRiderSid = item.riderSid;
      this.showViewRiderDetail = true;
    },
    deleteData(item){
      deleteOrdFreightRiderSelf(item.sid).then(result => {
        if (result) {
          this.pageNum = 1;
          this.getRiderList();
          this.$message.success("删除成功");
        }
      });
    },
    handleCloseAddSelfPickupGoodsRider() {
      this.getRiderList();
      this.showAddSelfPickupGoodsRider = false;

    },
    handleCloseViewRiderDetail() {
      this.showViewRiderDetail = false;
    },
    getIndex(rowIndex) {
      return (this.pageNum - 1) * this.pageSize + rowIndex +1;
    },
    dateFormatter(row, column, cellValue) {
      return cellValue?formatTime(cellValue,"YYYY-MM-DD HH:mm"):'';
    },
  },
  watch: {
    selectedStore(newVal) {
      this.rStores = newVal;
      this.pageNum = 1;
      this.getRiderList();
    },
  },
};
</script>

<style lang="scss" scoped>
.list-container{
  width: 100%;
  .toolbar{
    width: 100%;
    height: 50px;
  }
}
</style>
