<template>
  <el-dialog title="添加自取货骑手" :visible.sync="dialogVisible" append-to-body custom-class="dialog" :before-close="handleCancel">
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确认</el-button>
    </span>
    <div class="container">
      <SelectStore @selectStore="handleSelectStore"/>
      <div class="right-container">
        <div class="toolbar">
          <el-input placeholder="查询关键字" v-model="keyword" :clearable="true" :style="{ width:'250px',float: 'right'}">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </div>
        <el-table :data="riderList" highlight-current-row ref="table" @selection-change="handleSelectionChange" v-loading="loading" :max-height="tableMaxHeight">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column label="序号" type="index" align="center" width="60px" :index="getIndex"></el-table-column>
          <el-table-column label="姓名" align="center" width="70px" prop="cName"></el-table-column>
          <el-table-column label="性别" align="center" width="60px" >
            <template #default="scope">
              {{ getSex(scope.row.bSex) }}
            </template>
          </el-table-column>
          <el-table-column label="手机号码" align="center" width="110px" prop="cPhone"></el-table-column>
          <el-table-column label="所在门店" align="center" prop="sStore"></el-table-column>
          <el-table-column label="状态" align="center" width="60px" >
            <template #default="scope">
              {{ getStatus(scope.row.bStatus) }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          :layout="pageLayout"
          :total="total"
          :current-page="pageNum"
          :page-sizes="pageSelect"
          :page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :style="{marginTop: '15px'}">
        </el-pagination>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import SelectStore from "@/views/components/SelectStore";
import {sexMap, statusMap} from "@/utils/deliveryMap";
import {getOrdFreightRiderList,addOrdFreightRiderSelf} from "@/api/delivery";

export default {
  name: "AddSelfPickupGoodsRider",
  components: {SelectStore},
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tableMaxHeight: document.documentElement.clientHeight - 620,
      dialogVisible: this.visible,
      rStores:[],
      keyword:'',
      riderList:[],
      selectedRiderList:[],
      loading:false,
      total: 0, //总条数
      pageNum: 1, //当前页
      pageSize: 10, //每页显示条数
      pageSelect: [10, 20, 30, 50],
      pageLayout:"prev, pager, next,jumper,total,sizes",
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
    getStatus() {
      return function(bStatus) {
        return statusMap[bStatus] || '未知';
      };
    },
    getSex() {
      return function(bSex) {
        return sexMap[bSex] || '未知';
      };
    },
  },
  methods: {
    getRiderList() {
      this.selectedRiderList = [];
      let params = {
        search:this.keyword,
        rStores:this.rStores,
        pageNum:this.pageNum,
        pageSize:this.pageSize,
      }
      this.loading = true;
      getOrdFreightRiderList(params).then(result => {
        if (result) {
          this.riderList = result.rows;
          this.total = result.total;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    // 查询
    search(){
      this.pageNum = 1;
      this.getRiderList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.getRiderList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getRiderList();
    },
    handleSelectStore(e) {
      this.rStores = e;
    },
    getIndex(rowIndex) {
      return (this.pageNum - 1) * this.pageSize + rowIndex +1;
    },
    handleCancel() {
      this.dialogVisible = false;
    },
    handleSelectionChange(val) {
      this.selectedRiderList = val;
    },
    handleConfirm() {
      if(this.selectedRiderList.length === 0){
        this.$message.warning("请至少选择一个骑手添加");
        return;
      }
      this.loading = true;
      addOrdFreightRiderSelf({riderSids:this.selectedRiderList.map(item => item.sid)}).then(result => {
        if (result) {
          this.$message.success("添加成功");
          this.dialogVisible = false;
          this.$emit('close');
        }
      }).finally(() => {
        this.loading = false;
      });;
    }
  },
  watch: {
    visible(newVal) {
      this.dialogVisible = newVal;
      if(newVal){
        this.pageNum = 1;
        this.getRiderList();
      }
    },
    dialogVisible(newVal) {
      this.$emit('update:visible', newVal);
    },
    rStores(newVal) {
      this.pageNum = 1;
      this.getRiderList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .dialog{
  width: 900px!important;
  height: 720px!important;
}
::v-deep .el-dialog__body{
  padding: 20px 20px 20px 20px;
}
::v-deep .el-dialog {
  margin-top: 1vh!important;
}
.container{
  width: 100%;
  height: 550px;
  background-color: #fff;
  //padding: 20px;
}
.right-container{
  float:left;
  width:calc(100% - 240px);
  height: 100%;
}
.list-container{
  width: 100%;
  .toolbar{
    width: 100%;
    height: 50px;
  }
}
</style>
