<template>
  <el-dialog title="查看骑手信息" :visible.sync="dialogVisible" append-to-body custom-class="dialog" :before-close="handleCancel">
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleCancel">关闭</el-button>
    </span>
    <div class="container" v-loading="loading">
      <div class="item">
        <div class="title">手机号码</div>
        <div class="content">{{riderDetail?.cPhone}}</div>
      </div>
      <div class="item">
        <div class="title">姓名</div>
        <div class="content">{{riderDetail?.cName}}</div>
      </div>
      <div class="item">
        <div class="title">性别</div>
        <div class="content">{{sexMap[riderDetail?.bSex??0]}}</div>
      </div>
      <div class="item">
        <div class="title">已加盟的平台</div>
        <div class="content">{{riderDetail?.sPlatform}}</div>
      </div>
      <div class="item">
        <div class="title">所在区域</div>
        <div class="content">{{ findLabelsByIds(baseArea,riderDetail?.cRegion??[]) }}</div>
      </div>
      <div class="item">
        <div class="title">常住地址</div>
        <div class="content">{{riderDetail?.cAddress}}</div>
      </div>
      <div class="item">
        <div class="title">工作时间</div>
        <div class="content">全天</div>
      </div>
      <div class="item">
        <div class="title">启用状态</div>
        <div class="content">{{statusMap[riderDetail?.bStatus??1]}}</div>
      </div>
      <div class="item">
        <div class="title">登记时间</div>
        <div class="content">{{dateFormatter(riderDetail?.tCreate)}}</div>
      </div>
      <div class="item">
        <div class="title">骑手来源</div>
        <div class="content">{{riderDetail.sStore}}</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {sexMap,statusMap,specialMap} from "@/utils/deliveryMap";
import baseArea from "@/utils/baseArea";
import {getOrdFreightRiderDetail} from "@/api/delivery";
import {formatTime} from "@/utils/utils";
export default {
  name: "ViewRiderDetail",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading:false,
      sexMap:sexMap,
      specialMap:specialMap,
      statusMap:statusMap,
      baseArea:baseArea['data'],
      dialogVisible: this.visible,
      riderDetail: {
      },
    };
  },
  created() {
    this.getRiderDetail();
  },
  mounted() {
  },
  methods: {
    getRiderDetail() {
      if(this.sid){
        this.loading = true;
        getOrdFreightRiderDetail(this.sid).then(result => {
          if (result) {
            this.riderDetail = result.data;
            this.loading = false;
          }
        });
      }
    },
    dateFormatter(value) {
      return value?formatTime(value,"YYYY-MM-DD HH:mm"):'';
    },
    handleCancel() {
      this.riderDetail = {};
      this.dialogVisible = false;
    },
    findLabelsByIds(nodes, ids) {
      if(ids === undefined || ids.length === 0){
        return "";
      }
      const result = [];
      for (const nodeId of ids) {
        let foundNode = null;
        function traverseNodes(nodeArray) {
          for (const node of nodeArray) {
            if (node.NODE_CODE === nodeId) {
              foundNode = node;
              return true; // 找到匹配的节点，结束遍历
            } else if (node.children && node.children.length > 0) {
              if (traverseNodes(node.children)) {
                return true; // 在子节点中找到匹配的节点，结束遍历
              }
            }
          }
          return false; // 未找到匹配的节点
        }
        traverseNodes(nodes);
        if (foundNode) {
          result.push(foundNode.NODE_NAME); // 将找到的节点的label添加到结果数组中
        }
      }
      return result.join('-');
    },
  },
  watch: {
    visible(newVal) {
      if(newVal){
        this.getRiderDetail();
      }
      this.dialogVisible = newVal;
    },
    dialogVisible(newVal) {
      this.$emit('update:visible', newVal);
    }
  },
};
</script>

<style lang="scss"  scoped>
::v-deep .dialog{
  width: 450px!important;
}
::v-deep .el-dialog__body{
  //padding: 10px 20px 30px 20px;
}
::v-deep .el-dialog {
  margin-top: 1vh!important;
}
.container{
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  .item{
    width: 100%;
    height: 40px;
    .title{
      width: 100px;
      height: 40px;
      line-height: 40px;
      float: left;
      text-align: left;
    }
    .content{
      width: calc(100% - 100px);
      height: auto;
      line-height: 40px;
      float: left;
      text-align: right;
    }
  }
}
</style>
