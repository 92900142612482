<template>
  <el-dialog title="运费结算设置" :visible.sync="dialogVisible" append-to-body custom-class="dialog" :before-close="handleCancel">
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" :loading="loading">确认</el-button>
    </span>
    <div v-loading="loading">
      <el-form :model="form" :rules="rules" ref="form" label-width="100px" label-position="right">
        <el-form-item label="骑手姓名">
          <span>{{rider?.cName}}</span>
        </el-form-item>
        <el-form-item label="性别">
          <span>{{sexMap[rider?.bSex]}}</span>
        </el-form-item>
        <el-form-item label="手机号码">
          <span>{{rider?.cPhone}}</span>
        </el-form-item>
        <el-form-item label="账号户名" prop="cBankName">
          <el-input v-model="form.cBankName"></el-input>
        </el-form-item>
        <el-form-item label="银行账号" prop="cBankCode">
          <el-input v-model="form.cBankCode"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="cBank">
          <el-input v-model="form.cBank"></el-input>
        </el-form-item>
        <el-form-item label="支付方式" prop="bPay">
          <el-select v-model="form.bPay" placeholder="请选择支付方式" style="width: 100%">
            <el-option v-for="(item) in Object.keys(payMap)"
                       :key="item"
                       :label="payMap[item]"
                       :value="parseInt(item)">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结算方式" prop="bSettle">
          <el-select v-model="form.bSettle" placeholder="请选择结算方式" style="width: 100%">
            <el-option v-for="(item) in Object.keys(settleMap)"
                       :key="item"
                       :label="settleMap[item]"
                       :value="parseInt(item)">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import {sexMap,payMap,settleMap} from "@/utils/deliveryMap";
import {getOrdFreightRiderSettle,setOrdFreightRiderSettle} from "@/api/delivery";

export default {
  name: "RiderSettleSetting",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    rider: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      loading:false,
      sexMap:sexMap,
      payMap:payMap,
      settleMap:settleMap,
      dialogVisible: this.visible,
      form: {
        cBankName:'',
        cBankCode: '',
        cBank: '',
        bPay: 1,
        bSettle: 1
      },
      rules: {
        cBankName: [
          { required: true, message: '请输入账号户名', trigger: 'blur' },
          { max: 30, message: '长度不能超过30个字符', trigger: 'blur' }
        ],
        cBankCode: [
          { required: true, message: '请输入银行账号', trigger: 'blur' },
          { max: 30, message: '长度不能超过30个字符', trigger: 'blur' }
        ],
        cBank: [
          { required: true, message: '请输入开户银行', trigger: 'blur' },
          { max: 30, message: '长度不能超过30个字符', trigger: 'blur' }
        ],
        bPay: [
          { required: true, message: '请选择支付方式', trigger: 'change' }
        ],
        bSettle: [
          { required: true, message: '请选择结算方式', trigger: 'change' }
        ],
      },
    };
  },
  created() {
    //this.getRiderSettle();
  },
  mounted() {
  },
  methods: {
    getRiderSettle() {
      if(this.rider) {
        this.loading = true;
        getOrdFreightRiderSettle(this.rider.sid).then(result => {
          if (result) {
            this.form = result.data;
            this.loading = false;
          }
        });
      }
    },
    handleCancel() {
      this.$refs['form'].resetFields();
      this.dialogVisible = false;
    },
    handleConfirm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          let params = this.form;
          params.sid = this.rider.sid;
          setOrdFreightRiderSettle(params).then(result => {
            if (result) {
              this.$message.success("修改成功");
              this.dialogVisible = false;
              this.$emit('close');
            }
          }).finally(() => {
            this.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  },
  watch: {
    visible(newVal) {
      if(newVal){
        this.getRiderSettle();
      }
      this.dialogVisible = newVal;
    },
    dialogVisible(newVal) {
      this.$emit('update:visible', newVal);
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .dialog{
  width: 450px!important;
}
::v-deep .el-dialog__body{
  padding: 20px 20px 30px 20px;
}
::v-deep .el-dialog {
  margin-top: 1vh!important;
}
.container{
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  .item{
    width: 100%;
    height: 40px;
    .title{
      width: 100px;
      height: 40px;
      line-height: 40px;
      float: left;
      text-align: left;
    }
    .content{
      width: calc(100% - 100px);
      height: auto;
      line-height: 40px;
      float: left;
      text-align: right;
    }
  }
}
</style>
