import { render, staticRenderFns } from "./AddOrEditRiderDetail.vue?vue&type=template&id=8526edea&scoped=true"
import script from "./AddOrEditRiderDetail.vue?vue&type=script&lang=js"
export * from "./AddOrEditRiderDetail.vue?vue&type=script&lang=js"
import style0 from "./AddOrEditRiderDetail.vue?vue&type=style&index=0&id=8526edea&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8526edea",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/apple/IdeaProjects/banling-empty-delivery/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8526edea')) {
      api.createRecord('8526edea', component.options)
    } else {
      api.reload('8526edea', component.options)
    }
    module.hot.accept("./AddOrEditRiderDetail.vue?vue&type=template&id=8526edea&scoped=true", function () {
      api.rerender('8526edea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/DeliveryInfo/AddOrEditRiderDetail.vue"
export default component.exports