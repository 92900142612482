<template>
  <el-dialog :title="(sid?'编辑':'新增')+'骑手信息'" :visible.sync="dialogVisible" append-to-body custom-class="dialog" :before-close="handleCancel">
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" :loading="loading">确认</el-button>
    </span>
    <div v-loading="loading">
      <el-form :model="form" :rules="rules" ref="form" label-width="100px" label-position="left">
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="手机号码" prop="cPhone">
              <el-input v-model="form.cPhone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名" prop="cName">
              <el-input v-model="form.cName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="性别" prop="bSex">
              <el-select v-model="form.bSex" placeholder="请选择性别" style="width: 100%">
                <el-option v-for="(item) in Object.keys(sexMap)"
                           :key="item"
                           :label="sexMap[item]"
                           :value="parseInt(item)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="来源门店" prop="rStore">
              <el-cascader
                v-model="form.rStore"
                :options="storeList"
                :props="{ value: 'NODE_CODE',label: 'NODE_NAME' }"
                @change="handleStoreChange"
                filterable
                clearable
                style="width: 100%"
              ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="已加盟平台" prop="rPlatform">
              <el-select v-model="form.rPlatform" placeholder="请选择加盟平台" style="width: 100%">
                <el-option v-for="(item) in platformList"
                           :key="item.sid"
                           :label="item.cName"
                           :value="item.sid">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所在区域" prop="cRegion">
              <el-cascader
                v-model="form.cRegion"
                :options="baseArea"
                :props="{ value: 'NODE_CODE',label: 'NODE_NAME' }"
                @change="handleAreaChange"
                filterable
                clearable
                style="width: 100%"
              ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="常住地址" prop="cAddress">
              <el-input v-model="form.cAddress"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="配送备注" prop="cRemark">
              <el-input v-model="form.cRemark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="特殊人群" prop="bSpecial">
              <el-select v-model="form.bSpecial" placeholder="请选择特殊人群" style="width: 100%">
                <el-option v-for="(item) in Object.keys(specialMap)"
                           :key="item"
                           :label="specialMap[item]"
                           :value="parseInt(item)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="启用状态" prop="bStatus">
              <el-select v-model="form.bStatus" placeholder="请选择启用状态" style="width: 100%">
                <el-option v-for="(item) in Object.keys(statusMap)"
                           :key="item"
                           :label="statusMap[item]"
                           :value="parseInt(item)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-dialog>
</template>
<script>
import {sexMap,statusMap,specialMap} from "@/utils/deliveryMap";
import baseArea from "@/utils/baseArea";
import {phoneValidator} from "@/utils/validator";
import {getOrderStore, getOrdFreightPlatform,addOrdFreightRider,editOrdFreightRider,getOrdFreightRiderDetail} from "@/api/delivery";
export default {
  name: "AddOrEditRiderDetail",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading:false,
      regionKeyword:'',
      dialogVisible: this.visible,
      sexMap:sexMap,
      platformList:[],
      storeList: [],
      specialMap:specialMap,
      statusMap:statusMap,
      baseArea:baseArea['data'],
      form: {
        sid:'',
        cPhone: '',
        cName: '',
        bSex: 0,
        rStore:'',
        rPlatform:'',
        cRegion: '',
        cAddress: "",
        cRemark: "",
        bSpecial: 1,
        bStatus: 1
      },
      rules: {
        cPhone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { validator: phoneValidator, trigger: 'blur' },
        ],
        cName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { max: 10, message: '长度不能超过10个字符', trigger: 'blur' }
        ],
        bSex: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        rStore: [
          { required: true, message: '请选择门店', trigger: 'change' }
        ],
        rPlatform: [
          { required: true, message: '请选择加盟平台', trigger: 'change' }
        ],
        cRegion: [
          { required: true, message: '请选择所在区域', trigger: 'change' }
        ],
      },
    };
  },
  created() {
    this.getPlatformList();
    this.getStoreList();
  },
  mounted() {

  },
  methods: {
    getRiderDetail() {
      if(this.sid){
        this.loading = true;
        getOrdFreightRiderDetail(this.sid).then(result => {
          if (result) {
            this.form = result.data;
            this.form.rStore = this.getParentIds(result.data.rStore,this.storeList);
            this.loading = false;
          }
        });
      }else{
        this.form = {
          sid:'',
          cPhone: '',
          cName: '',
          bSex: 0,
          rStore:'',
          rPlatform:'',
          cRegion: '',
          cAddress: "",
          cRemark: "",
          bSpecial: 1,
          bStatus: 1
        };
      }
    },
    getPlatformList() {
      let params = {
        search:'',
        pageNum:1,
        pageSize:10000,
      }
      getOrdFreightPlatform(params).then(result => {
        if (result) {
          this.platformList = result.rows;
        }
      });
    },
    getStoreList() {
      getOrderStore({}).then(result => {
        if (result) {
          this.storeList = result.data;
        }
      });
    },
    handleStoreChange(value) {
    },
    handleAreaChange(value) {
    },
    handleCancel() {
      this.$refs['form'].resetFields();
      this.dialogVisible = false;
    },
    handleConfirm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          let params = this.form;
          params.rStore = this.form['rStore'][2];
          if(!this.sid){
            addOrdFreightRider(params).then(result => {
              if (result) {
                this.$message.success("添加成功");
                this.dialogVisible = false;
                this.$emit('close');
              }
            }).finally(() => {
              this.loading = false;
            });
          }else{
            editOrdFreightRider(params).then(result => {
              if (result) {
                this.$message.success("修改成功");
                this.dialogVisible = false;
                this.$emit('close');
              }
            }).finally(() => {
              this.loading = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    // 获取父级id数组
    getParentIds(nodeId, tree, currentPath = []) {
      for (const node of tree) {
        if (node.children && node.children.length > 0) {
          // 如果有子节点，则递归地在子节点中查找
          const parentPath = this.getParentIds(nodeId, node.children, [...currentPath, node.NODE_CODE]);
          if (parentPath.length > 0) {
            // 如果在子节点中找到了目标节点，则返回完整的结果路径
            return parentPath;
          }
        } else if (node.NODE_CODE === nodeId) {
          // 如果找到了目标节点，则返回当前路径（包含所有父节点ID和目标节点ID）
          return [...currentPath, nodeId];
        }
      }
      // 如果没有找到目标节点，则返回空数组
      return [];
    },
  },
  watch: {
    visible(newVal) {
      if(newVal){
        this.getRiderDetail();
      }
      this.dialogVisible = newVal;
    },
    dialogVisible(newVal) {
      this.$emit('update:visible', newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .dialog{
  width: 900px!important;
}
::v-deep .el-dialog {
  margin-top: 1vh!important;
}
</style>
