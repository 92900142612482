<template>
  <div class="container">
    <SelectStore @selectStore="handleSelectStore"/>
    <div class="right-container">
      <el-tabs v-model="activeTab" type="border-card" @tab-click="handleTabClick">
        <el-tab-pane label="骑手信息管理" name="rider-info" key="rider-info">
          <RiderList ref="riderList" :selectedStore="selectedStore"/>
        </el-tab-pane>
        <el-tab-pane label="自取货骑手信息管理" name="self-pickup-info" key="self-pickup-info">
          <SelfPickupGoodsRiderList ref="selfPickupList" :selectedStore="selectedStore"/>
        </el-tab-pane>
        <el-tab-pane label="自配送骑手信息管理" name="self-delivery-info" key="self-delivery-info">
          <SelfDeliveryRiderList ref="selfDeliveryList" :selectedStore="selectedStore"/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import SelectStore from "@/views/components/SelectStore";

import RiderList from "@/views/components/DeliveryInfo/RiderList";
import SelfPickupGoodsRiderList from "@/views/components/DeliveryInfo/SelfPickupGoodsRiderList";
import SelfDeliveryRiderList from "@/views/components/DeliveryInfo/SelfDeliveryRiderList";

export default {
  name: "deliveryInfo",
  components: {SelectStore,RiderList,SelfPickupGoodsRiderList,SelfDeliveryRiderList},
  data() {
    return {
      height: document.documentElement.clientHeight - 142 + "px",
      selectedStore:[],
      activeTab: 'rider-info',
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    handleSelectStore(e) {
      this.selectedStore = e;
    },
    handleTabClick(tab) {
      if (tab.name === 'rider-info' && this.$refs.riderList) {
        this.$refs.riderList.search();
      } else if (tab.name === 'self-pickup-info' && this.$refs.selfPickupList) {
        this.$refs.selfPickupList.search();
      } else if (tab.name === 'self-delivery-info' && this.$refs.selfDeliveryList) {
        this.$refs.selfDeliveryList.search();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
}
.right-container{
  float:left;
  width:calc(100% - 240px);
  height: 100%;
}
.list-container{
  width: 100%;
  .toolbar{
    width: 100%;
    height: 50px;
  }
}
::v-deep .el-tabs__item{
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}
</style>
