<template>
  <div class="list-container" :style="{height: height}">
    <div class="toolbar">
      <el-input placeholder="查询关键字" v-model="keyword" :clearable="true" :style="{ width:'250px',float: 'right'}">
        <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
      </el-input>
    </div>
    <el-table :data="riderList" highlight-current-row ref="table" v-loading="loading" :max-height="tableMaxHeight" :size="'medium'">
      <el-table-column label="序号" type="index" align="center" width="50px" :index="getIndex"></el-table-column>
      <el-table-column label="姓名" align="center" width="80px" prop="cName"></el-table-column>
      <el-table-column label="性别" align="center" width="50px" >
        <template #default="scope">
          {{ getSex(scope.row.bSex) }}
        </template>
      </el-table-column>
      <el-table-column label="手机号码" align="center" width="95px" prop="cPhone"></el-table-column>
      <el-table-column label="所在门店" align="center" width="200px" prop="sStore"></el-table-column>
      <el-table-column label="更新时间" align="center" width="120px" prop="tCreate" :formatter="dateFormatter"></el-table-column>
      <el-table-column label="结算方式" align="center" width="90px" >
        <template #default="scope">
          {{ getSettle(scope.row.bSettle) }}
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" width="55px" >
        <template #default="scope">
          {{ getStatus(scope.row.bStatus) }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" align="center" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="view(scope.row)">详情</el-button>
          <el-button type="text" @click="freightSetting(scope.row)">设置</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :layout="pageLayout"
      :total="total"
      :current-page="pageNum"
      :page-sizes="pageSelect"
      :page-size="pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :style="{marginTop: '15px'}">
    </el-pagination>
    <ViewRiderDetail :visible.sync="showViewRiderDetail" :sid="currentSid" @close="handleCloseViewRiderDetail"/>
    <RiderSettleSetting :visible.sync="showFreightSetting" :rider="currentRider" @close="handleCloseFreightSetting"/>
  </div>
</template>

<script>
import {getOrdFreightRiderList} from "@/api/delivery";
import {sexMap,statusMap,settleMap} from "@/utils/deliveryMap";
import {formatTime} from "@/utils/utils";
import ViewRiderDetail from "@/views/components/DeliveryInfo/ViewRiderDetail";
import RiderSettleSetting from "@/views/components/DeliveryInfo/RiderSettleSetting";

export default {
  name: "SelfDeliveryRiderList",
  components: {ViewRiderDetail,RiderSettleSetting},
  props: {
    selectedStore: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      height: document.documentElement.clientHeight - 142 + "px",
      tableMaxHeight: document.documentElement.clientHeight - 260,
      currentSid:'',
      currentRider:null,
      keyword:'',
      rStores:[],
      riderList:[],
      showFreightSetting: false,
      showViewRiderDetail: false,
      loading:false,
      total: 0, //总条数
      pageNum: 1, //当前页
      pageSize: 10, //每页显示条数
      pageSelect: [10, 20, 30, 50],
      pageLayout:"prev, pager, next,jumper,total,sizes",
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
    getStatus() {
      return function(bStatus) {
        return statusMap[bStatus] || '未知';
      };
    },
    getSex() {
      return function(bSex) {
        return sexMap[bSex] || '未知';
      };
    },
    getSettle() {
      return function(bsettle) {
        return settleMap[bsettle] || '未知';
      };
    },
  },
  methods: {
    getRiderList() {
      let params = {
        type:2,
        search:this.keyword,
        rStores:this.rStores,
        pageNum:this.pageNum,
        pageSize:this.pageSize,
      }
      this.loading = true;
      getOrdFreightRiderList(params).then(result => {
        if (result) {
          this.riderList = result.rows;
          this.total = result.total;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    // 查询
    search(){
      this.pageNum = 1;
      this.getRiderList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.getRiderList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getRiderList();
    },
    view(item){
      this.currentSid = item.sid;
      this.showViewRiderDetail = true;
    },
    freightSetting(item){
      this.currentRider = item;
      this.showFreightSetting = true;
    },
    handleCloseViewRiderDetail() {
      this.showViewRiderDetail = false;
    },
    handleCloseFreightSetting() {
      this.getRiderList();
      this.showFreightSetting = false;
    },
    getIndex(rowIndex) {
      return (this.pageNum - 1) * this.pageSize + rowIndex +1;
    },
    dateFormatter(row, column, cellValue) {
      return cellValue?formatTime(cellValue,"YYYY-MM-DD HH:mm"):'';
    },
  },
  watch: {
    selectedStore(newVal) {
      this.rStores = newVal;
      this.pageNum = 1;
      this.getRiderList();
    },
  },
};
</script>

<style lang="scss" scoped>
.list-container{
  width: 100%;
  .toolbar{
    width: 100%;
    height: 50px;
  }
}
</style>
